import RewardsBrandingCupIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingCupIcon.svelte";
import RewardsBrandingGiftIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingGiftIcon.svelte";
import RewardsBrandingRibbonIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingRibbonIcon.svelte";
import RewardsBrandingStarIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingStarIcon.svelte";
import RewardsBrandingTicketIcon from "@apps/rewards/components/Icons/RewardsBrandingIconsOptions/RewardsBrandingTicketIcon.svelte";

import { REWARDS_BRANDING_LAUNCHER_ICON_TYPES } from "./rewardsBrandingTypes";

//Variables
export const RTL_DIRECTION = "rtl";
export const REMOVE_PROTOCOL_REGEX = /^https?:\/\//;
export const REFERRAL_SHARE_PATH = "referral";
export const REFERRAL_SHARE_CHANNELTYPE = "channelType";
export const POINTS_SHARE_PATH = "points";
export const EARNING_RULE_SHARE_RULE_ID = "ruleId";
export const EARNING_RULE_SHARE_EVENT_TYPE = "eventType";
export const SOCIAL_SHARE_CHANNELNAME = "channelName";
export const TOKEN = "token";
export const REWARDS_STOREFRONT_BASE_URL =
    __APP_ENV__ === "development"
        ? "https://app.dev.growave.io/storefront-api/rewards-storefront/v2"
        : "https://app.growave.io/storefront-api/rewards-storefront/v2";
export const SHARE_LINK = "{{ share_link }} ";
export const VERIFY_ACCOUNT_MODAL_NAME = "verify-account-modal";
export const JOIN_NOW_MODAL_NAME = "join-now-modal";
export const DISCOUNT_COOKIE_KEY = "discount_code";
export const REFERRAL_CONTEXT_STORAGE_KEY = "gwReferralContext";
export const REFERRAL_CONTEXT_SEARCH_PARAM = "referralContext";
export const REWARDS_HOME_ANCHOR = "rewards-home";
export const PERCENTAGE_SYMBOL = "%";
export const MAIL_TO_BODY = "mailto:?body=";
export const REWARDS_QR_CODE_MODAL_NAME = "rewards-qr-code-modal";

//Enums
export enum KeyBoardEventKeys {
    ArrowDown = "ArrowDown",
    ArrowUp = "ArrowUp",
    Space = "Space",
    Escape = "Escape",
    Enter = "Enter",
}

export enum RewardsWidgetsPlaceholders {
    rewardsPopUpWidgetWrapperPlaceholder = ".gw-widget-placeholder",
    rewardsPopUpRootWidgetPlaceholder = ".gw-rd-popup-root-widget-placeholder",
    rewardsJoinNowModalWidgetPlaceholder = "body",
    rewardsVerifyAccountModalWidgetPlaceholder = "body",
    rewardsWaysToEarnPageWidgetPlaceholder = ".gw-rd-ways-to-earn-page-widget-placeholder",
    rewardsWaysToEarnPageWidgetModalPlaceholder = "body",
    rewardsWaysToSpendPageWidgetPlaceholder = ".gw-rd-ways-to-spend-page-widget-placeholder",
    rewardsWaysToSpendPageWidgetModalPlaceholder = "body",
    rewardsReferralProgramPageWidgetPlaceholder = ".gw-rd-referral-program-page-widget-placeholder",
    rewardsCustomerRewardsModalPlaceholder = "body",
    rewardsVipTiersPageWidgetPlaceholder = ".gw-rd-vip-tiers-page-widget-placeholder",
    rewardsVipTiersCardWidgetPlaceholder = ".gw-rd-vip-tiers-card-widget-placeholder",
    RewardsVipTiersSingleProgressWidgetPlaceholder = ".gw-rd-vip-tiers-single-progress-widget-placeholder",
    rewardsEarnPointsProductPagePlaceholder = ".gw-rd-earn-points-product-page-widget-placeholder",
    rewardsLoyaltyInfoPageWidgetPlaceholder = ".gw-rd-loyalty-info-page-widget-placeholder",
    rewardsEmptyStatePageWidgetPlaceholder = ".gw-rd-page-wrapper",
    rewardsQrCodeModalPlaceholder = "body",
    rewardsCustomerPointsBalanceInlineWidgetPlaceholder = ".gw-rd-customer-points-balance-inline-widget-placeholder",
}

export enum RewardsTranslationNamespaces {
    commonCriticalPoints = "common.critical.Points",
    rewardsCriticalPopUpWidget = "rewards.critical.RewardsPopUpWidget",
    rewardsPopUpWidget = "rewards.RewardsPopUpWidget",
    rewardsWaysToEarnPageWidget = "rewards.RewardsWaysToEarnPageWidget",
    rewardsWaysToSpendPageWidget = "rewards.RewardsWaysToSpendPageWidget",
    rewardsVipTiersPageWidget = "rewards.RewardsVipTiersPageWidget",
    rewardsVipTiersCardWidget = "rewards.RewardsVipTiersCardWidget",
    rewardsVipTiersSingleProgressWidget = "rewards.RewardsVipTiersSingleProgressWidget",
    rewardsReferralProgramPageWidget = "rewards.RewardsReferralProgramPageWidget",
    rewardsEmptyStatePageWidget = "rewards.RewardsEmptyStatePageWidget",
    rewardsLoyaltyInfoPageWidget = "rewards.RewardsLoyaltyInfoPageWidget",
    rewardsCriticalEarnPointsProductPageWidget = "rewards.critical.RewardsEarnPointsProductPageWidget",
}

//Do not delete or edit enum REWARDS_DATA_GW_SELECTORS
export enum REWARDS_DATA_GW_SELECTORS {
    earningRulePopup = "gw-earning-rule-popup",
    earningRulePage = "gw-earning-rule-page",
    earningRulePageModal = "gw-earning-rule-page-modal",
    spendingRulePopup = "gw-spending-rule-popup",
    spendingRulePage = "gw-spending-rule-page",
    spendingRulePageModal = "gw-spending-rule-page-modal",
    tierPopup = "gw-tier-popup",
    tierPage = "gw-tier-page",
    rewardsVipTiersCardWidget = "gw-rd-vip-tiers-card-widget",
    customerRewardsPopup = "gw-customer-rewards-popup",
    customerRewardsPage = "gw-customer-rewards-page",
    customerRewardsPageModal = "gw-customer-rewards-page-modal",
    qrCodeRewardsModal = "gw-qr-code-rewards-modal",
}

export const REWARDS_BRANDING_ICONS = {
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.default]: RewardsBrandingCupIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.gift]: RewardsBrandingGiftIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.ticket]: RewardsBrandingTicketIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.star]: RewardsBrandingStarIcon,
    [REWARDS_BRANDING_LAUNCHER_ICON_TYPES.ribbon]: RewardsBrandingRibbonIcon,
};
