import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const WishlistApplicationConfigSchema = object({
    wishlistsWidgetPlaceholder: string(),
    drawerWidgetPlaceholderSelector: string(),
    addToWishlistProductPageButtonSelector: string(),
    addToWishlistProductCardSelector: string(),
    goToWishlistPageHeaderWidgetInjector: string(),
    wishlistPageWidgetPlaceholderSelector: string(),
    sharedWishlistPageWidgetPlaceholderSelector: string(),
    saveWishlistModalWidgetPlaceholderSelector: string(),
});

export type WishlistApplicationConfigInterface = Infer<
    typeof WishlistApplicationConfigSchema
>;

export const WishlistApplicationConfigToken = Symbol(
    "WishlistApplicationConfigToken"
) as InjectionToken<WishlistApplicationConfigInterface>;
