import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

export const ReviewsApplicationConfigToken = Symbol(
    "ReviewsApplicationConfigToken"
);

export const reviewsApplicationLoggerToken = Symbol(
    "ReviewsApplicationLoggerToken"
);

export const reviewsAuthApiClientToken = Symbol(
    "reviewsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const reviewsTranslationsAuthApiClientToken = Symbol(
    "reviewsTranslationsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const LeaveReviewPageApplicationConfigToken = Symbol(
    "LeaveReviewPageApplicationConfigToken"
);
